import styled from "styled-components";
import { useCtx, useCourseNodeCtx } from "../Context";
import { FormControl, InputLabel, Select } from "common/components";
import { openConfirm } from "common/confirmBox";
import { ProgramCourseMapHelpers } from "@nait-aits/ui";
import { UndoIcon } from "common/icons";

export const gradePoints = [
  { letter: "A/A+", grade: 4 },
  { letter: "A-", grade: 3.7 },
  { letter: "B+", grade: 3.3 },
  { letter: "B", grade: 3 },
  { letter: "B-", grade: 2.7 },
  { letter: "C+", grade: 2.3 },
  { letter: "C", grade: 2 },
  { letter: "C-", grade: 1.7 },
  { letter: "D+", grade: 1.3 },
  { letter: "D", grade: 1 },
  { letter: "P", grade: Infinity },
  { letter: "F", grade: 0 },
] as const;

const MapGradeDropdown = () => {
  const { courses } = useCtx();
  const { course, termIndex, updateCourses, isFailed, isDisabled } =
    useCourseNodeCtx();

  return (
    <Dropdown variant="outlined" fullWidth>
      <InputLabel>Select Grade</InputLabel>
      <Select
        native
        fullWidth
        label="Select Grade"
        disabled={isDisabled(course.preRequisites)}
        value={isFailed ? 0 : course.grade ?? ""}
        onChange={(e) => {
          const grade = +(e.target.value as string);
          if (grade === 0 && course.grade) {
            // Set from graded to failed.
            const postRequsCodes = ProgramCourseMapHelpers.getAllPostRequs(
              courses,
              course.courseCode
            );
            const postRequs = courses.filter(
              (x) =>
                x.grade !== undefined && postRequsCodes.includes(x.courseCode)
            );
            if (postRequs.length > 0) {
              openConfirm({
                title: "Warning",
                description: (
                  <div>
                    You cannot set <b>{course.courseCode}</b> to failed because
                    the following course
                    {postRequs.length === 1
                      ? " grade has"
                      : " grades have"}{" "}
                    been set.
                    {postRequs.map((pr) => (
                      <p key={`post_${pr.courseCode}`}>
                        <b>{pr.courseCode}</b>:{" "}
                        {
                          gradePoints.find((gp) => gp.grade === pr.grade)
                            ?.letter
                        }
                      </p>
                    ))}
                    You can try <b>RESTART</b>, <UndoOnText /> or click on the{" "}
                    <b>CLEAR GRADE{postRequs.length > 1 ? "S" : ""}</b> button
                    below to reset the grade of
                    {postRequs.length === 1 ? " this course" : " these courses"}
                    .
                  </div>
                ),
                okBtnText: `Clear Grade${postRequs.length > 1 ? "s" : ""}`,
                callback: () => {
                  updateCourses(
                    courses.map((x) =>
                      postRequsCodes.includes(x.courseCode)
                        ? { ...x, grade: undefined }
                        : x.courseCode === course.courseCode
                        ? {
                            ...x,
                            grade: undefined,
                            atTermIndexes: [
                              ...x.atTermIndexes,
                              course.atTermIndexes.max()! + 1,
                            ],
                          }
                        : x
                    )
                  );
                },
              });
              return;
            }
          } else if (grade !== 0 && termIndex !== course.atTermIndexes.max()) {
            // Set from failed to graded.
            const postRequsCodes = ProgramCourseMapHelpers.getAllPostRequs(
              courses,
              course.courseCode
            );
            const process = () => {
              updateCourses(
                courses.map((x) =>
                  postRequsCodes.includes(x.courseCode)
                    ? {
                        ...x,
                        grade: undefined,
                        atTermIndexes: [
                          courses.find((c) => c.courseCode === x.courseCode)!
                            .startTerm,
                        ],
                      }
                    : x.courseCode === course.courseCode
                    ? {
                        ...x,
                        grade,
                        atTermIndexes: x.atTermIndexes.filter(
                          (a) => a <= termIndex + 1
                        ) as ArrayAtLeastOne<number>,
                      }
                    : x
                )
              );
            };
            if (postRequsCodes.length > 0) {
              openConfirm({
                title: "Warning",
                description: (
                  <div>
                    The course <b>{course.courseCode}</b> had been set to failed
                    previously. If you want to continue, the following
                    {postRequsCodes.length === 1 ? " course" : " courses"} will
                    be reset.
                    {postRequsCodes.map((pr) => {
                      const pGrade = gradePoints.find(
                        (gp) =>
                          gp.grade ===
                          courses.find((p) => p.courseCode === pr)?.grade
                      )?.letter;
                      return (
                        <p key={`post_${pr}`}>
                          <b>{pr}</b>
                          {pGrade && `: ${pGrade}`}
                        </p>
                      );
                    })}
                  </div>
                ),
                callback: process,
              });
            } else process();
            return;
          }
          // Regular set to graded/failed.
          updateCourses(
            courses.map((x) =>
              x.courseCode === course.courseCode
                ? {
                    ...x,
                    grade: grade === 0 ? undefined : grade,
                    atTermIndexes:
                      grade === 0
                        ? [...x.atTermIndexes, course.atTermIndexes.max()! + 1]
                        : x.atTermIndexes,
                  }
                : x
            )
          );
        }}
      >
        {course.grade === undefined &&
          termIndex === course.atTermIndexes.max() && <option />}
        {gradePoints.map((gp) => (
          <option key={`grade_${gp.letter}`} value={gp.grade}>
            {gp.letter} ({gp.grade === Infinity ? "Pass" : gp.grade.toFixed(1)})
          </option>
        ))}
      </Select>
    </Dropdown>
  );
};

export default MapGradeDropdown;

const Dropdown = styled(FormControl)`
  margin-top: 0.5em !important;
  & select {
    padding: 0.4em;
    font-size: 0.75rem;
    margin: 0.25em 0;
    &:disabled {
      cursor: not-allowed;
    }
  }
  & label {
    transform: translate(0.5em, 0.5em) scale(0.8);
  }
`;
const UndoOnText = styled(UndoIcon)`
  vertical-align: bottom;
`;
