import styled from "styled-components";
import { useCtx } from "../Context";
import { terms } from "common/helpers";
import { ErrorIcon } from "common/icons";
import { getTermText } from "./Setup";

const MapTermTitle = ({
  index,
  selectedCourseCode,
}: {
  index: number;
  selectedCourseCode?: string;
}) => {
  const { horiMode, courses, year, maxPreTerm, canCourseInTerm } = useCtx();

  return (
    <TitleDiv horiMode={horiMode}>
      <TermTitle greyOut={!canCourseInTerm(selectedCourseCode ?? "", index)}>
        <div>{terms[index % 4]}</div>
        <div>{getTermText(year, index)}</div>
      </TermTitle>
      {courses.filter((c) => c.atTermIndexes.includes(index)).length >
        maxPreTerm && (
        <ExceededWarning>
          <ErrorIcon />
          <span>
            Exceeded Maximum
            <br />
            Courses Per Term
          </span>
        </ExceededWarning>
      )}
    </TitleDiv>
  );
};

export default MapTermTitle;

const TitleDiv = styled.div<{ horiMode: boolean }>`
  ${(props) =>
    props.horiMode &&
    `
    & > div {
      display: inline-block;
      &:first-child {
        width: 150px;
      }
    }
  `}
`;
const TermTitle = styled.div<{ greyOut?: boolean }>`
  margin-top: 30px;
  line-height: 1.5;
  ${(props) => props.greyOut && `color: #ccc;`}
  & > div:first-child {
    text-transform: uppercase;
    font-size: 14px;
  }
  & > div:nth-child(2) {
    font-weight: bold;
  }
`;
const ExceededWarning = styled.div`
  background-color: #ffe5e5;
  border-radius: 10px;
  padding: 10px 5px;
  color: #ff0000;
  width: 177px;
  font-size: 12px;
  margin-top: 12px;
  & > * {
    display: inline-block;
    margin-right: 5px;
  }
`;
