import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { usePrevious, useUndoableState } from "@nait-aits/helper/hooks";
import Banner from "./components/Banner";
import ControlPanel from "./components/ControlPanel";
import Setup, { getYear } from "./components/Setup";
import MapTermTitle from "./components/MapTermTitle";
import MapMoveButtons from "./components/MapMoveButtons";
import MapGradeDropdown, { gradePoints } from "./components/MapGradeDropdown";
import { openConfirm } from "common/confirmBox";
import { SHA512Hash, AESDecrypt } from "@nait-aits/helper/cryptography";
import { Loading } from "common/components";
import { ProgramCourseMap, ProgramCourseMapHelpers } from "@nait-aits/ui";
import appStore from "store";
import {
  CtxProvider,
  CourseNodeCtxProvider,
  SchedulePlannerCourse,
} from "./Context";
import type { PresetPlan } from "./schedulePlanner.duck";

type Props = {
  programId: string;
  year: string;
  share: string;
};

const lsRoot = "AT_SchedulePlanner_";
const getLSKeyPrefix = (props: Partial<Props>) =>
  `${lsRoot}${props.programId}_${props.year}_`;
const getStartTerm = (lsKey: string) => {
  const st = +localStorage[lsKey];
  return st < 0 || st > 3 || isNaN(st) ? undefined : st;
};
const getMaxPreTerm = (lsKey: string) => {
  const max = +localStorage[lsKey];
  return max < 5 || isNaN(max) ? 5 : max;
};
const getWithGPA = (lsKey: string) => localStorage[lsKey] === "true";

const SchedulePlanner = () => {
  const { programId, year, share } = useParams<Props>();
  const props = { programId, year };
  const navigate = useNavigate();
  const lsKeyStartTerm = `${getLSKeyPrefix(props)}StartTerm`;
  const [startTerm, setStartTerm] = useState(undefined as number | undefined);
  const lsKeyMax = `${getLSKeyPrefix(props)}max`;
  const [maxPreTerm, setMaxPreTerm] = useState(5);
  const lsKeyWithGPA = `${getLSKeyPrefix(props)}withGPA`;
  const [withGPA, setWithGPA] = useState(false);
  const lsKeyOnlyCourseCodes = `${getLSKeyPrefix(props)}onlyCourseCodes`;
  const [onlyCourseCodes, setOnlyCourseCodes] = useState(
    undefined as string[] | undefined
  );
  const [courses, setCourses, { undoable, undo, redoable, redo }] =
    useUndoableState([] as SchedulePlannerCourse[], true);
  const lsHoriMode = `${getLSKeyPrefix(props)}horiMode`;
  const [horiMode, setHoriMode] = useState(
    localStorage[lsHoriMode] === "true" ?? false
  );
  const [selectedNode, setSelectedNode] = useState(
    undefined as
      | undefined
      | { timestamp: Date; node?: { courseCode: string; termIndex: number } }
  );

  const { isLoaded, programCourses, offerings, isErrored, presetPlans } =
    appStore.useReduxState((e) => e.schedulePlanner);
  const actions = appStore.useActions().schedulePlanner;

  const lsKey = `${getLSKeyPrefix(props)}${SHA512Hash(
    JSON.stringify(programCourses)
  )}`;
  const canCourseInTerm = (courseCode: string, termIndex: number) =>
    offerings
      .filter(
        (o) =>
          o.year <= getYear(+year!, termIndex) &&
          o.term === termIndex % 4 &&
          o.course === courseCode
      )
      .orderBy((o) => o.year, "desc")[0]?.offered !== false ?? true;

  useEffect(() => {
    document.title = "Schedule Planner";
  }, []);

  const prevPresets = usePrevious(presetPlans);
  useEffect(() => {
    if (
      share?.startsWith("planId:") &&
      prevPresets === undefined &&
      presetPlans !== undefined
    ) {
      navigate(`/SchedulePlanner/${programId}/${year}`, { replace: true });
      try {
        const selectedPlan = presetPlans?.find(
          (p) => p.id === +share.slice(7) && !p.hidden && !p.invalidMap
        );
        if (selectedPlan) onStart(selectedPlan);
      } catch {}
    }
  }, [share, presetPlans]);
  useEffect(() => {
    if (share && !share.startsWith("planId:")) {
      navigate(`/SchedulePlanner/${programId}/${year}`, { replace: true });
      try {
        const shareObj = JSON.parse(
          AESDecrypt(share, "schedulePlanner")
        ) as ObjectType<string>;
        for (const p in shareObj) localStorage[p] = shareObj[p];
      } catch {}
    }
  }, [share]);

  const prevShare = usePrevious(share);
  useEffect(() => {
    if (prevShare && !share) return;
    setStartTerm(getStartTerm(lsKeyStartTerm));
    setMaxPreTerm(getMaxPreTerm(lsKeyMax));
    setWithGPA(getWithGPA(lsKeyWithGPA));
    setOnlyCourseCodes(localStorage[lsKeyOnlyCourseCodes]?.split(","));
    actions.getPresetPlans({ programId: +programId!, year: +year! });
    actions.getProgramMap({
      programId: +programId!,
      year: +year!,
      useMinTerm: true,
    });
  }, [programId, year, share]);

  const prevIsLoaded = usePrevious(isLoaded);
  const prevStartTerm = usePrevious(startTerm);
  useEffect(() => {
    if (
      (isLoaded !== prevIsLoaded || startTerm !== prevStartTerm) &&
      isLoaded &&
      startTerm !== undefined
    ) {
      const init = programCourses
        .filter(
          (e) => !onlyCourseCodes || onlyCourseCodes.includes(e.courseCode)
        )
        .map((p) => ({
          ...p,
          atTermIndexes:
            p.atTermIndexes[0] < startTerm!
              ? ([startTerm!] as ArrayAtLeastOne<number>)
              : p.atTermIndexes,
          canCourseInTermIndex: (termIndex: number) =>
            canCourseInTerm(p.courseCode, termIndex),
        })) as SchedulePlannerCourse[];

      try {
        const lsValues = JSON.parse(localStorage[lsKey]) as Pick<
          SchedulePlannerCourse,
          "atTermIndexes" | "ordinals" | "courseCode" | "grade"
        >[];
        init.forEach((c) => {
          const fromLS = lsValues.find((l) => l.courseCode === c.courseCode);
          if (fromLS) {
            c.atTermIndexes = withGPA
              ? fromLS.atTermIndexes
              : [fromLS.atTermIndexes[0]];
            c.ordinals = fromLS.ordinals;
            if (withGPA) c.grade = fromLS.grade;
          }
        });
      } catch {}

      const toBeFixed = ProgramCourseMapHelpers.fixProgramMap(init);
      setCourses(
        ProgramCourseMapHelpers.polishProgramMap(
          toBeFixed.isValid ? init : toBeFixed.fixed
        ),
        true
      );
    }
  }, [isLoaded, startTerm]);

  useEffect(() => {
    if (courses.length > 0) {
      localStorage[lsKey] = JSON.stringify(
        courses.map((c) => ({
          courseCode: c.courseCode,
          atTermIndexes: c.atTermIndexes,
          ordinals: c.ordinals,
          grade: c.grade,
        }))
      );
    }
  }, [courses]);

  useEffect(() => {
    if (isErrored) navigate("/404", { replace: true });
  }, [isErrored]);

  if (!isLoaded || !presetPlans) return <Loading />;

  const maxTerms = ProgramCourseMapHelpers.getMaxTerm(courses);
  const maxTermCourses =
    ProgramCourseMapHelpers.getMaxTermCoursesCount(courses);
  const updateCourses = (update: typeof courses, selectCourseNode?: string) => {
    const fix = ProgramCourseMapHelpers.fixProgramMap(update);
    const toBeUpdate = fix.isValid ? update : fix.fixed;
    setCourses(toBeUpdate);

    selectCourseNode &&
      setSelectedNode({
        timestamp: new Date(),
        node: {
          courseCode: selectCourseNode,
          termIndex:
            toBeUpdate
              .find((x) => x.courseCode === selectCourseNode)
              ?.atTermIndexes.max() ?? -1,
        },
      });
  };
  const numOfGraded = courses.filter(
    (c) => c.grade && c.grade !== Infinity
  ).length;
  const isDisabled = (preRequisites: string[]) =>
    Boolean(withGPA) &&
    courses.filter((c) => preRequisites.includes(c.courseCode) && !c.grade)
      .length > 0;

  const onStart = (plan: PresetPlan) => {
    setStartTerm(undefined);
    setTimeout(() => {
      const onlyCourseCodes = plan.notFullMap
        ? (JSON.parse(plan.mapData) as { courseCode: string }[]).map(
            (e) => e.courseCode
          )
        : undefined;
      localStorage[lsKeyOnlyCourseCodes] = onlyCourseCodes?.join(",");
      setOnlyCourseCodes(onlyCourseCodes);
      localStorage[lsKey] = plan.mapData;
      localStorage[lsKeyStartTerm] = plan.startTerm;
      setStartTerm(plan.startTerm);
      localStorage[lsKeyMax] = plan.maxPerTerm;
      setMaxPreTerm(plan.maxPerTerm);
      localStorage[lsKeyWithGPA] = plan.withGPA ?? false;
      setWithGPA(plan.withGPA ?? false);
      setSelectedNode({ timestamp: new Date(), node: undefined });
    });
  };

  return (
    <CtxProvider
      value={{
        year: +year!,
        lsKey,
        lsKeyStartTerm,
        lsKeyMax,
        lsKeyWithGPA,
        courses,
        horiMode,
        maxPreTerm,
        canCourseInTerm,
      }}
    >
      <Banner />
      {startTerm && (
        <ControlPanel
          restartClick={() =>
            openConfirm({
              title: "Are you sure you want to restart?",
              callback: () => {
                delete localStorage[lsKeyStartTerm];
                setStartTerm(undefined);
                delete localStorage[lsKeyMax];
                setMaxPreTerm(5);
                setWithGPA(false);
                delete localStorage[lsKeyWithGPA];
                setOnlyCourseCodes(undefined);
                delete localStorage[lsKeyOnlyCourseCodes];
                delete localStorage[lsKey];
              },
            })
          }
          undoable={undoable}
          undoClick={() => {
            undo();
            setSelectedNode({ timestamp: new Date() });
          }}
          redoable={redoable}
          redoClick={() => {
            redo();
            setSelectedNode({ timestamp: new Date() });
          }}
          viewMode={{
            isHori: horiMode,
            onChange: () => {
              setHoriMode(!horiMode);
              localStorage[lsHoriMode] = !horiMode;
            },
          }}
          gpaInfo={
            withGPA && (
              <h3>
                Graduation GPA:{" "}
                {(
                  (courses.filter((c) => c.grade !== Infinity).sum("grade") ??
                    0) / (numOfGraded === 0 ? 1 : numOfGraded)
                ).toFixed(2)}
              </h3>
            )
          }
        />
      )}
      {startTerm === undefined ? (
        <Setup onStart={onStart} />
      ) : (
        <MapContainer>
          <ProgramCourseMap
            horizontalMode={horiMode}
            courses={courses}
            disableEvenOffset
            titleOffset={maxTermCourses > maxPreTerm && !horiMode ? 120 : 60}
            getTermTitle={(i, selectedCourseCode) => ({
              override: (
                <MapTermTitle
                  index={i}
                  selectedCourseCode={selectedCourseCode}
                />
              ),
            })}
            positionDistince={
              horiMode ? { x: 205, y: 210 } : { x: 275, y: 120 }
            }
            nodeSize={{ height: 70, width: 165 }}
            connectorMode="sameCourse"
            selectedNodeOverride={selectedNode}
            viewportStyle={{
              minHeight: "calc(100vh - 170px)",
              height: horiMode
                ? maxTerms * 220 + 20
                : maxTermCourses * 120 +
                  120 +
                  (maxTermCourses > maxPreTerm ? 60 : 0),
              width: horiMode ? maxTermCourses * 205 + 100 : maxTerms * 285,
              minWidth: "100%",
            }}
            nodeStyle={(c, termIndex, currStyle) => {
              let ret = currStyle;
              if (withGPA) {
                if (
                  c.atTermIndexes.length > 1 &&
                  termIndex < c.atTermIndexes.max()!
                )
                  ret = { ...currStyle, backgroundColor: "#ffb6c1" };
                if (isDisabled(c.preRequisites))
                  ret = {
                    ...currStyle,
                    backgroundColor: "#eee",
                    color: "#888",
                    border: 0,
                  };
                if (c.grade)
                  ret = {
                    ...currStyle,
                    backgroundColor: "#d7f3d8",
                    border: 0,
                  };
              }

              if (
                courses.filter((c) => c.atTermIndexes.includes(termIndex))
                  .length > maxPreTerm
              ) {
                ret = { ...ret, border: "#ff0000 2px solid" };
              }
              return ret;
            }}
            nodeOverride={(c, termIndex, _, isSelected, ordinals) => {
              const isFailed =
                withGPA &&
                c.atTermIndexes.length > 1 &&
                termIndex < c.atTermIndexes.max()!;
              const gradePoint = gradePoints.find((g) => g.grade === c.grade);

              return (
                <CourseNodeCtxProvider
                  value={{
                    course: c,
                    termIndex,
                    ordinals,
                    updateCourses,
                    startTerm,
                    isFailed,
                    isDisabled,
                  }}
                >
                  <CourseNode
                    small={
                      withGPA &&
                      (isFailed || gradePoint !== undefined || isSelected)
                    }
                  >
                    <b className="code">{c.courseCode}</b>
                    <div className="title">{c.title}</div>
                    {isSelected && <MapMoveButtons />}
                    {withGPA && (
                      <>
                        {isSelected && <MapGradeDropdown />}
                        {isFailed && !isSelected && (
                          <GradeLabel>Failed</GradeLabel>
                        )}
                        {!isFailed && !isSelected && (
                          <GradeLabel>
                            {gradePoint &&
                              `${gradePoint.letter} (${
                                gradePoint.grade === Infinity
                                  ? "Pass"
                                  : gradePoint.grade.toFixed(1)
                              })`}
                          </GradeLabel>
                        )}
                      </>
                    )}
                  </CourseNode>
                </CourseNodeCtxProvider>
              );
            }}
          />
        </MapContainer>
      )}
    </CtxProvider>
  );
};

export default SchedulePlanner;

const MapContainer = styled.div`
  height: calc(100vh - 152px);
  overflow: auto;
`;
const CourseNode = styled.div<{ small: boolean }>`
  height: 68px;
  width: 160px;
  line-height: ${(props) => (props.small ? 1 : 1.5)};
  position: relative;
  & .code {
    display: block;
    margin-bottom: 5px;
  }
  & .title {
    ${(props) =>
      props.small
        ? `
      font-size: 12px;
      white-space: nowrap;
    `
        : `
      font-size: 13px;
      height: 40px;
    `}
    overflow: hidden;
  }
`;
const GradeLabel = styled.div`
  font-weight: bold;
  margin-top: 0.75em;
  height: 25px;
`;
