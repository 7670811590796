import { ReactNode } from "react";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const controlName = "confirmBox";

export type Options = {
  title: ReactNode;
  description?: ReactNode;
  okBtnText?: string;
  callback: () => void;
};
type State = {
  isOpen: boolean;
  options: Options;
};

const initialState: State = {
  isOpen: false,
  options: {
    title: undefined,
    callback: () => {},
  },
};

const slice = createSlice({
  name: controlName,
  initialState,
  reducers: {
    openConfirm: (_: State, action: PayloadAction<Options>) => ({
      isOpen: true,
      options: action.payload,
    }),
    closeConfirm: () => initialState,
  },
});

const ret = {
  reducer: {
    [controlName]: slice.reducer,
  },
  actions: {
    [controlName]: {
      ...slice.actions,
    },
  },
};

export default ret;
