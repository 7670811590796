import { useEffect } from "react";
import styled from "styled-components";

const title = "Page Not Found!";

const Home = () => {
  useEffect(() => {
    document.title = title;
  }, []);

  return (
    <Container>
      <strong>404</strong>
      <h1>{title}</h1>
    </Container>
  );
};

export default Home;

const Container = styled.div`
  margin: 100px auto 20px;
  width: 700px;
  text-align: center;
  & strong {
    font-size: 100px;
  }
`;
