import { createStore } from "@nait-aits/redux";
import confirmBoxState from "./common/confirmBox/confirmBox.duck";
import programMapStore from "pages/programMap/programMap.duck";
import schedulePlannerStore from "pages/schedulePlanner/schedulePlanner.duck";

export default createStore(
  {
    ...confirmBoxState.reducer,
    ...programMapStore.reducer,
    ...schedulePlannerStore.reducer,
  },
  {
    ...confirmBoxState.actions,
    ...programMapStore.actions,
    ...schedulePlannerStore.actions,
  }
);
