import {
  ButtonPriCont,
  ButtonSecCont,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "../components";
import appStore from "store";
import { Options } from "./confirmBox.duck";
import { useEffect } from "react";

let openConfirmMethod: (options: Options) => void;

export const ConfirmBox = () => {
  const { isOpen, options } = appStore.useReduxState((e) => e.confirmBox);
  const { title, description, okBtnText, callback } = options;
  const actions = appStore.useActions().confirmBox;

  useEffect(() => {
    if (!openConfirmMethod)
      openConfirmMethod = (options: Options) => actions.openConfirm(options);
  }, []);

  return isOpen ? (
    <Dialog open={isOpen}>
      <DialogTitle>{title}</DialogTitle>
      {description && <DialogContent>{description}</DialogContent>}
      <DialogActions>
        <ButtonPriCont
          onClick={() => {
            callback();
            actions.closeConfirm();
          }}
        >
          {okBtnText ?? "OK"}
        </ButtonPriCont>
        <ButtonSecCont onClick={() => actions.closeConfirm()}>
          Cancel
        </ButtonSecCont>
      </DialogActions>
    </Dialog>
  ) : (
    <></>
  );
};

export const openConfirm = (options: Options) => openConfirmMethod?.(options);
