import { useEffect } from "react";
import styled from "styled-components";
import { NAITIcon } from "common/icons";

const title = "NAIT Program Scheduling Map";

const Home = () => {
  useEffect(() => {
    document.title = title;
  }, []);

  return (
    <Container>
      <NAITIcon fontSize="6rem" />
      <h1>{title}</h1>
    </Container>
  );
};

export default Home;

const Container = styled.div`
  margin: 100px auto 20px;
  width: 700px;
  text-align: center;
`;
