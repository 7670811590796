import styled from "styled-components";
import { useCtx, useCourseNodeCtx } from "../Context";
import {
  ExpandMoreIcon,
  ExpandLessIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "common/icons";
import { IconButton } from "common/components";

const MapMoveButtons = () => {
  const { horiMode, courses, canCourseInTerm } = useCtx();
  const { course, termIndex, ordinals, updateCourses, startTerm } =
    useCourseNodeCtx();

  const currOrdinal = ordinals.indexOf(course.courseCode);
  const currRG = course.coReqGroupId
    ? courses
        .filter(
          (x) =>
            course.coReqGroupId === x.coReqGroupId &&
            x.atTermIndexes.includes(termIndex)
        )
        .map((x) => x.courseCode)
    : [course.courseCode];

  const updateCourseOrdinal = (
    course: typeof courses[number],
    termIndex: number,
    newOrdinal: number
  ) => ({
    ...course,
    ordinals: course.atTermIndexes.map((a, aIndex) =>
      a === termIndex ? newOrdinal : course.ordinals?.[aIndex]
    ) as ArrayAtLeastOne<number>,
  });

  const max = course.atTermIndexes.max()!;
  const coReqs = courses
    .filter(
      (x) =>
        course.coReqGroupId !== undefined &&
        course.coReqGroupId === x.coReqGroupId &&
        !x.grade &&
        x.atTermIndexes.isEqualTo(course.atTermIndexes)
    )
    .map((x) => x.courseCode);
  const preReqs = [
    ...course.preRequisites,
    ...courses
      .filter((x) => coReqs.includes(x.courseCode))
      .flatMap((x) => x.preRequisites),
  ].uniq();

  let prevAvailableTerm = undefined as number | undefined;
  for (let testTerm = termIndex - 1; testTerm >= startTerm; testTerm--) {
    if (canCourseInTerm(course.courseCode, testTerm)) {
      prevAvailableTerm = testTerm;
      break;
    }
  }

  const canMoveBack =
    prevAvailableTerm !== undefined &&
    (preReqs.length === 0 ||
      prevAvailableTerm >
        courses
          .filter((x) => preReqs.includes(x.courseCode))
          .flatMap((x) => x.atTermIndexes)
          .max()!) &&
    (course.atTermIndexes.length === 1 ||
      prevAvailableTerm > (course.atTermIndexes.without(max).max() ?? -1)) &&
    course.atTermIndexes.min()! > startTerm;

  return (
    <MoveButtons>
      <IconButton
        className={horiMode ? "left" : "up"}
        disabled={ordinals.indexOf(course.courseCode) === 0}
        onClick={() => {
          const prev = courses.find(
            (x) => x.courseCode === ordinals[currOrdinal - 1]
          )!;
          const prevRG = prev.coReqGroupId
            ? courses
                .filter(
                  (x) =>
                    prev.coReqGroupId === x.coReqGroupId &&
                    x.atTermIndexes.includes(termIndex)
                )
                .map((x) => x.courseCode)
            : [prev.courseCode];
          const isSameRG = course.coReqGroupId === prev.coReqGroupId;
          const currRGMove = isSameRG ? 1 : prevRG.length;
          const prevRGMove = isSameRG ? 0 : currRG.length - 1;

          updateCourses(
            courses.map((x) =>
              ordinals.includes(x.courseCode)
                ? updateCourseOrdinal(
                    x,
                    termIndex,
                    x.courseCode === course.courseCode
                      ? currOrdinal - currRGMove
                      : x.courseCode === prev.courseCode
                      ? currOrdinal + prevRGMove
                      : currRG.includes(x.courseCode) && !isSameRG
                      ? ordinals.indexOf(x.courseCode) - currRGMove
                      : prevRG.includes(x.courseCode) && !isSameRG
                      ? ordinals.indexOf(x.courseCode) + prevRGMove + 1
                      : ordinals.indexOf(x.courseCode)
                  )
                : x
            )
          );
        }}
      >
        {horiMode ? <ChevronLeftIcon /> : <ExpandLessIcon />}
      </IconButton>
      <IconButton
        className={horiMode ? "right" : "down"}
        disabled={
          ordinals.indexOf(course.courseCode) ===
          courses.filter((x) => x.atTermIndexes.includes(termIndex)).length - 1
        }
        onClick={() => {
          const next = courses.find(
            (x) => x.courseCode === ordinals[currOrdinal + 1]
          )!;
          const nextRG = next.coReqGroupId
            ? courses
                .filter((x) => next.coReqGroupId === x.coReqGroupId)
                .map((x) => x.courseCode)
            : [next.courseCode];
          const isSameRG = course.coReqGroupId === next.coReqGroupId;
          const currRGMove = isSameRG ? 1 : nextRG.length;
          const nextRGMove = isSameRG ? 0 : currRG.length - 1;

          updateCourses(
            courses.map((x) =>
              ordinals.includes(x.courseCode)
                ? updateCourseOrdinal(
                    x,
                    termIndex,
                    x.courseCode === course.courseCode
                      ? currOrdinal + currRGMove
                      : x.courseCode === next.courseCode
                      ? currOrdinal - nextRGMove
                      : currRG.includes(x.courseCode) && !isSameRG
                      ? ordinals.indexOf(x.courseCode) + currRGMove
                      : nextRG.includes(x.courseCode) && !isSameRG
                      ? ordinals.indexOf(x.courseCode) - nextRGMove - 1
                      : ordinals.indexOf(x.courseCode)
                  )
                : x
            )
          );
        }}
      >
        {horiMode ? <ChevronRightIcon /> : <ExpandMoreIcon />}
      </IconButton>
      <IconButton
        className={horiMode ? "up" : "left"}
        disabled={!canMoveBack}
        onClick={() => {
          updateCourses(
            courses.map((x) =>
              x.courseCode === course.courseCode ||
              (coReqs.includes(x.courseCode) &&
                course.coRequisites.includes(x.courseCode))
                ? {
                    ...x,
                    atTermIndexes: [
                      ...x.atTermIndexes.without(max),
                      prevAvailableTerm!,
                    ],
                  }
                : x
            ),
            course.courseCode
          );
        }}
      >
        {horiMode ? <ExpandLessIcon /> : <ChevronLeftIcon />}
      </IconButton>
      <IconButton
        className={horiMode ? "down" : "right"}
        onClick={() => {
          updateCourses(
            courses.map((x) =>
              x.courseCode === course.courseCode ||
              (coReqs.includes(x.courseCode) &&
                x.coRequisites.includes(course.courseCode))
                ? {
                    ...x,
                    atTermIndexes: [...x.atTermIndexes.without(max), max + 1],
                  }
                : x
            ),
            course.courseCode
          );
        }}
      >
        {horiMode ? <ExpandMoreIcon /> : <ChevronRightIcon />}
      </IconButton>
    </MoveButtons>
  );
};

export default MapMoveButtons;

const MoveButtons = styled.div`
  & button {
    z-index: 100;
    border-radius: 50%;
    height: 24px;
    width: 24px;
    position: absolute;
    background-color: #1976d2 !important;
    & svg {
      color: #fff;
    }
    &.up {
      top: -22px;
      left: calc(50% - 12px);
    }
    &.down {
      bottom: -22px;
      left: calc(50% - 12px);
    }
    &.left {
      left: -22px;
      top: calc(50% - 12px);
    }
    &.right {
      right: -22px;
      top: calc(50% - 12px);
    }
    &:disabled {
      background-color: #d6d6d6 !important;
    }
  }
`;
