import { createTheme } from "@material-ui/core/styles";
import grey from "@material-ui/core/colors/grey";
import lightBlue from "@material-ui/core/colors/lightBlue";

export default createTheme({
  palette: {
    primary: { main: lightBlue["800"] },
    secondary: { main: grey["600"] },
  },
  props: {
    MuiRadio: { color: "primary" },
    MuiCheckbox: { color: "primary" },
    MuiSwitch: { color: "primary" },
    MuiSelect: { displayEmpty: true },
    MuiDialog: { TransitionProps: { style: { overflow: "visible" } } },
  },
  overrides: {
    MuiDialog: {
      paperWidthSm: { maxWidth: 768 },
    },
    MuiButton: {
      contained: { minWidth: 88 },
      text: { minWidth: 88 },
      containedSizeSmall: { padding: "4px 8px", minWidth: 64 },
    },
    MuiSelect: {
      icon: { color: lightBlue["800"] },
      select: {
        paddingRight: "32px !important",
        "&:focus": {
          background: "none !important",
          color: `${lightBlue["800"]} !important`,
        },
        "&$disabled": {
          cursor: "not-allowed",
          "& ~ svg": { color: grey["700"] },
        },
      },
    },
    MuiTypography: {
      body1: { fontSize: "0.875rem", letterSpacing: "0.01071em" },
    },
  },
});
