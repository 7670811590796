import { createSlice } from "@reduxjs/toolkit";
import { createAsyncAction } from "@nait-aits/redux";
import { baseURL } from "common/helpers";

const controlName = "schedulePlanner";

type CourseItem = {
  courseCode: string;
  title: string;
  preRequisites: string[];
  coRequisites: string[];
  startTerm: number;
};

type Offering = {
  year: number;
  term: number;
  course: string;
  offered: boolean;
};

type ProgramCourse = CourseItem & {
  canCourseInTermIndex?: (termIndex: number) => boolean;
  atTermIndexes: ArrayAtLeastOne<number>;
};

export type PresetPlan = {
  id: number;
  title: string;
  author: string;
  lastUpdated: string;
  effectiveYear: number;
  startTerm: number;
  maxPerTerm: number;
  mapData: string;
  image: string;
  hidden?: boolean;
  withGPA?: boolean;
  invalidMap?: boolean;
  notFullMap?: boolean;
};

type State = {
  isLoaded: boolean;
  title: string;
  school: string;
  programCourses: ProgramCourse[];
  offerings: Offering[];
  offeredTerms: number[];
  isErrored: boolean;
  presetPlans?: PresetPlan[];
};

const initialState: State = {
  isLoaded: false,
  title: "",
  school: "",
  programCourses: [],
  offerings: [],
  offeredTerms: [],
  isErrored: false,
  presetPlans: undefined,
};

const generateStartTerm = (offeredTerms: number[], startTerm: number) =>
  [0, 1, 2, 3].flatMap((t) => offeredTerms.map((o) => t * 4 + o))[startTerm];

const getProgramMap = createAsyncAction<
  {
    title: string;
    school: string;
    programCourses: CourseItem[];
    offerings: Offering[];
    offeredTerms: number[];
  },
  { programId: number; year: number; useMinTerm?: boolean },
  State
>({
  actionPrefix: controlName,
  actionName: "getProgramMap",
  url: `${baseURL}ProgramMap/GetProgramMap`,
  pending: (state) => {
    state.isLoaded = false;
    state.programCourses = [];
    state.offerings = [];
    state.offeredTerms = [];
  },
  fulfilled: (state, action) => {
    state.isLoaded = true;
    state.title = action.payload.title;
    state.school = action.payload.school;
    state.programCourses = action.payload.programCourses.map((c) => ({
      ...c,
      atTermIndexes: [
        generateStartTerm(action.payload.offeredTerms, c.startTerm),
      ],
    }));
    state.offerings = action.payload.offerings;
    state.offeredTerms = action.payload.offeredTerms;
  },
  rejected: (state) => {
    state.isLoaded = true;
    state.isErrored = true;
  },
});

const getPresetPlans = createAsyncAction<
  string[],
  { programId: number; year: number },
  State
>({
  actionPrefix: controlName,
  actionName: "getPresetPlans",
  url: `${baseURL}ProgramMap/GetPresetPlans`,
  pending: (state) => {
    state.presetPlans = undefined;
  },
  fulfilled: (state, action) => {
    state.presetPlans = action.payload.map<PresetPlan>((p) => JSON.parse(p));
  },
  rejected: () => {},
});

const slice = createSlice({
  name: controlName,
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: {
    ...getProgramMap.reducer,
    ...getPresetPlans.reducer,
  },
});

const ret = {
  reducer: {
    [controlName]: slice.reducer,
  },
  actions: {
    [controlName]: {
      ...slice.actions,
      getProgramMap: getProgramMap.action,
      getPresetPlans: getPresetPlans.action,
    },
  },
};

export default ret;
