import { ReactNode } from "react";
import styled from "styled-components";
import {
  ButtonPriText,
  IconButton,
  Tooltip,
  FormControlLabel,
  Checkbox,
} from "common/components";
import { RedoIcon, UndoIcon } from "common/icons";
import ShareControl from "./ShareControl";

type Props = {
  restartClick: () => void;
  undoable: boolean;
  undoClick: () => void;
  redoable: boolean;
  redoClick: () => void;
  viewMode: { isHori: boolean; onChange: Function };
  gpaInfo: ReactNode;
};

const ControlPanel = ({
  restartClick,
  undoable,
  undoClick,
  redoable,
  redoClick,
  viewMode,
  gpaInfo,
}: Props) => (
  <BtnContainer>
    <ButtonPriText onClick={restartClick}>Restart</ButtonPriText>
    <Tooltip title={undoable ? "Undo" : ""}>
      <IconButton disabled={!undoable} onClick={undoClick}>
        <UndoIcon />
      </IconButton>
    </Tooltip>
    <Tooltip title={redoable ? "Redo" : ""}>
      <IconButton disabled={!redoable} onClick={redoClick}>
        <RedoIcon />
      </IconButton>
    </Tooltip>
    <CheckboxLabel
      label="Horizontal Mode"
      control={
        <Checkbox
          checked={viewMode.isHori}
          color="primary"
          onChange={() => viewMode.onChange()}
        />
      }
    />
    <ShareControl />
    {gpaInfo}
  </BtnContainer>
);

export default ControlPanel;

const BtnContainer = styled.div`
  border: 2px solid #d2d3d4;
  border-left: none;
  border-right: none;
  padding: 0px 50px;
  background-color: #eef1f5;
  & button {
    margin-right: 10px;
  }
  & h3 {
    float: right;
    line-height: 48px;
    margin: 0;
  }
`;
const CheckboxLabel = styled(FormControlLabel)`
  margin-left: 0 !important;
`;
