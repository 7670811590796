import styled from "styled-components";
import MuiButton, { ButtonProps } from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

export { default as Checkbox } from "@material-ui/core/Checkbox";
export { default as Dialog } from "@material-ui/core/Dialog";
export { default as DialogActions } from "@material-ui/core/DialogActions";
export { default as DialogContent } from "@material-ui/core/DialogContent";
export { default as DialogTitle } from "@material-ui/core/DialogTitle";
export { default as FormControl } from "@material-ui/core/FormControl";
export { default as FormControlLabel } from "@material-ui/core/FormControlLabel";
export { default as Grid } from "@material-ui/core/Grid";
export { default as Hidden } from "@material-ui/core/Hidden";
export { default as IconButton } from "@material-ui/core/IconButton";
export { default as InputLabel } from "@material-ui/core/InputLabel";
export { default as Radio } from "@material-ui/core/Radio";
export { default as RadioGroup } from "@material-ui/core/RadioGroup";
export { default as Select } from "@material-ui/core/Select";
export { default as Snackbar } from "@material-ui/core/Snackbar";
export { default as Tooltip } from "@material-ui/core/Tooltip";

const Button = (props: ButtonProps) => <MuiButton {...props} />;
const ButtonPri = Button.partialArg({ color: "primary" as "primary" });
const ButtonSec = Button.partialArg({ color: "secondary" as "secondary" });
export const ButtonPriCont = ButtonPri.partialArg({
  variant: "contained" as "contained",
});
export const ButtonPriText = ButtonPri.partialArg({
  variant: "text" as "text",
});
export const ButtonSecCont = ButtonSec.partialArg({
  variant: "contained" as "contained",
});
export const ButtonSecText = ButtonSec.partialArg({
  variant: "text" as "text",
});

export const Loading = () => (
  <LoadingContainer>
    <CircularProgress /> Loading...
  </LoadingContainer>
);

const LoadingContainer = styled.div`
  background-color: #fff;
  padding: 4em;
  height: 100vh;
  text-align: center;
  & > div {
    vertical-align: middle;
    margin-right: 1em;
  }
`;
