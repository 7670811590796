import { Icons } from "@nait-aits/ui";

export { default as ChevronLeftIcon } from "@material-ui/icons/ChevronLeft";
export { default as ChevronRightIcon } from "@material-ui/icons/ChevronRight";
export { default as CloseIcon } from "@material-ui/icons/Close";
export { default as ErrorIcon } from "@material-ui/icons/Error";
export { default as ExpandLessIcon } from "@material-ui/icons/ExpandLess";
export { default as ExpandMoreIcon } from "@material-ui/icons/ExpandMore";
export { default as InfoIcon } from "@material-ui/icons/Info";
export { default as RedoIcon } from "@material-ui/icons/Redo";
export { default as ShareIcon } from "@material-ui/icons/Share";
export { default as UndoIcon } from "@material-ui/icons/Undo";
export { default as WarningIcon } from "@material-ui/icons/Warning";
export const NAITIcon = Icons.NAIT;
