import { ProgramMapCourse } from "@nait-aits/ui";
import { createContext, useContext } from "react";

export type SchedulePlannerCourse = ProgramMapCourse<{
  grade?: number;
  startTerm: number;
}>;

type CtxProps = {
  year: number;
  lsKey: string;
  lsKeyStartTerm: string;
  lsKeyMax: string;
  lsKeyWithGPA: string;
  courses: SchedulePlannerCourse[];
  horiMode: boolean;
  maxPreTerm: number;
  canCourseInTerm: (courseCode: string, termIndex: number) => boolean;
};

const Ctx = createContext<CtxProps | undefined>(undefined);

export const CtxProvider = Ctx.Provider;

export const useCtx = () => {
  const context = useContext(Ctx);
  if (!context) throw new Error();
  return context;
};

type CourseNodeCtxProps = {
  course: SchedulePlannerCourse;
  termIndex: number;
  ordinals: string[];
  updateCourses: (
    update: SchedulePlannerCourse[],
    selectCourseNode?: string
  ) => void;
  startTerm: number;
  isFailed: boolean;
  isDisabled: (preRequisites: string[]) => boolean;
};

const CourseNodeCtx = createContext<CourseNodeCtxProps | undefined>(undefined);

export const CourseNodeCtxProvider = CourseNodeCtx.Provider;

export const useCourseNodeCtx = () => {
  const context = useContext(CourseNodeCtx);
  if (!context) throw new Error();
  return context;
};
