import { createSlice } from "@reduxjs/toolkit";
import { createAsyncAction } from "@nait-aits/redux";
import { baseURL } from "common/helpers";

const controlName = "programMap";

type CourseItem = {
  courseCode: string;
  title: string;
  preRequisites: string[];
  coRequisites: string[];
  startTerm: number;
};

type Offering = {
  year: number;
  term: number;
  course: string;
  offered: boolean;
};

type ProgramCourse = CourseItem & {
  canCourseInTermIndex?: (termIndex: number) => boolean;
  atTermIndexes: ArrayAtLeastOne<number>;
};

type State = {
  isLoading: boolean;
  title: string;
  school: string;
  programCourses: ProgramCourse[];
  pathways: {
    title: string;
    programCourses: ProgramCourse[];
  }[];
  offerings: Offering[];
  offeredTerms: number[];
  isErrored: boolean;
};

const initialState: State = {
  isLoading: false,
  title: "",
  school: "",
  programCourses: [],
  pathways: [],
  offerings: [],
  offeredTerms: [],
  isErrored: false,
};

const getProgramMap = createAsyncAction<
  {
    title: string;
    school: string;
    programCourses: CourseItem[];
    pathways?: { title: string; programCourses: CourseItem[] }[];
    offerings: Offering[];
    offeredTerms: number[];
  },
  { programId: number; year: number },
  State
>({
  actionPrefix: controlName,
  actionName: "getProgramMap",
  url: `${baseURL}ProgramMap/GetProgramMap`,
  pending: (state) => {
    state.isLoading = true;
    state.programCourses = [];
    state.pathways = [];
    state.offerings = [];
    state.offeredTerms = [];
    state.isErrored = false;
  },
  fulfilled: (state, action) => {
    state.isLoading = false;
    state.title = action.payload.title;
    state.school = action.payload.school;
    state.programCourses = action.payload.programCourses.map((c) => ({
      ...c,
      atTermIndexes: [c.startTerm],
    }));
    state.pathways =
      action.payload.pathways?.map((p) => ({
        ...p,
        programCourses: p.programCourses.map((pc) => ({
          ...pc,
          atTermIndexes: [pc.startTerm],
        })),
      })) ?? [];
    state.offerings = action.payload.offerings;
    state.offeredTerms = action.payload.offeredTerms;
  },
  rejected: (state) => {
    state.isLoading = false;
    state.isErrored = true;
  },
});

const slice = createSlice({
  name: controlName,
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: {
    ...getProgramMap.reducer,
  },
});

const ret = {
  reducer: {
    [controlName]: slice.reducer,
  },
  actions: {
    [controlName]: {
      ...slice.actions,
      getProgramMap: getProgramMap.action,
    },
  },
};

export default ret;
