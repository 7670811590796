import styled from "styled-components";
import appStore from "store";
import { NAITIcon } from "common/icons";
import { Grid, Hidden } from "common/components";
import { toAcademicYear } from "common/helpers";
import { useCtx } from "../Context";

const Banner = () => {
  const { year } = useCtx();
  const { school, title } = appStore.useReduxState((e) => e.schedulePlanner);

  return (
    <Header>
      <Grid container>
        <Hidden lgUp>
          <Grid sm={12} item>
            <InfoDiv>
              <MidSection sm>{toAcademicYear(year)}</MidSection>
              <div className="program">{title}</div>
            </InfoDiv>
          </Grid>
        </Hidden>
        <Hidden mdDown>
          <Grid sm={5} item>
            <NAITIcon fontSize={60} />
            <InfoDiv>
              <div>{school}</div>
              <div className="program">{title}</div>
            </InfoDiv>
          </Grid>
          <Grid sm={4} container item alignContent="center">
            <MidSection>{toAcademicYear(year)}</MidSection>
          </Grid>
        </Hidden>
      </Grid>
    </Header>
  );
};

export default Banner;

const Header = styled.div`
  height: 100px;
  background-color: #002d74;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("https://www.nait.ca/App_Themes/NAIT.caStyles/img/starburst-overlay.svg");
  padding: 20px 50px;
  color: #fff;
`;
const InfoDiv = styled.div`
  display: inline-block;
  vertical-align: top;
  padding: 2px;
  width: calc(100% - 64px);
  & .program {
    font-size: 24px;
    margin-top: 5px;
  }
  & div {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;
const MidSection = styled.div<{ sm?: boolean }>`
  ::after {
    content: " Schedule Planner";
  }
  font-size: ${(props) => (props.sm ? 22 : 30)}px;
`;
