import { useEffect } from "react";
import { useOneState } from "@nait-aits/helper/hooks";
import styled from "styled-components";
import { terms } from "common/helpers";
import { ButtonPriCont } from "common/components";
import appStore from "store";
import { useCtx } from "../Context";
import type { PresetPlan } from "../schedulePlanner.duck";

const termMonths = ["July", "September", "January", "May"];
export const getYear = (year: number, term: number) =>
  year + Math.floor(term / 4);
export const getTermText = (year: number, term: number) =>
  `${termMonths[term % 4]} ${getYear(year, term) + (term % 4 > 1 ? 1 : 0)}`;

const Setup = ({ onStart }: { onStart: (plan: PresetPlan) => void }) => {
  const { year } = useCtx();
  const [{ startTerm, planId }, { updater, setOnlyPlanId }] = useOneState({
    startTerm: -1,
    max: 5,
    planId: -1,
  });
  const { programCourses, offeredTerms, offerings, presetPlans } =
    appStore.useReduxState((e) => e.schedulePlanner);
  const noPreReqs = programCourses
    .filter((e) => e.preRequisites.length === 0)
    .map((e) => e.courseCode);
  const minTerm = offerings
    .filter((e) => noPreReqs.includes(e.course) && e.year === year && e.offered)
    .min((e) => e.term)?.term!;
  const plans =
    presetPlans?.filter(
      (p) => !p.hidden && !p.invalidMap && p.startTerm === startTerm
    ) ?? [];
  const plan = plans.find((p) => p.id === planId);

  useEffect(() => {
    if (plans.length === 1) setOnlyPlanId(plans[0].id);
  }, [startTerm]);

  if (!presetPlans) return <></>;

  return (
    <Container>
      <span>Pick a start term:</span>
      <div>
        {terms.map((t, tIndex) =>
          tIndex >= minTerm && offeredTerms.includes(tIndex) ? (
            <TermSelector
              disabled={startTerm === tIndex}
              key={`term_${tIndex}`}
              className={startTerm === tIndex ? "selected" : ""}
              onClick={() =>
                updater.setStartTerm(tIndex).setPlanId(-1).commit()
              }
            >
              <div>{getTermText(year, tIndex)}</div>
              <span>{t}</span>
            </TermSelector>
          ) : undefined
        )}
      </div>
      {plans.length === 0 && startTerm !== -1 && (
        <p>No plan has been setup for this term.</p>
      )}
      {plans.length > 0 && startTerm !== -1 && (
        <>
          <span>Select a plan:</span>
          <div>
            {plans.map((p) => (
              <TermSelector
                key={`plan_${p.id}`}
                className={planId === p.id ? "selected" : ""}
                onClick={() => setOnlyPlanId(p.id)}
              >
                <div>{p.title}</div>
              </TermSelector>
            ))}
          </div>
        </>
      )}
      <ButtonPriCont
        disabled={startTerm < 0 || startTerm > 3 || !plan}
        size="large"
        onClick={() => {
          if (plan) onStart(plan);
        }}
      >
        Start
      </ButtonPriCont>
      <ImgContainer>{plan && <img src={plan.image} alt="" />}</ImgContainer>
    </Container>
  );
};

export default Setup;

const Container = styled.div`
  background-color: #fff;
  height: calc(100vh - 100px);
  overflow: auto;
  padding: 50px;
  & > span {
    font-size: 1.5rem;
  }
  & > div {
    margin: 20px 0;
  }
`;
const TermSelector = styled.button`
  margin-right: 20px;
  margin-bottom: 20px;
  font-size: 1.5rem;
  background-color: #f5f7fa;
  border: 2px solid #e1e4ea;
  color: #000;
  border-radius: 5px;
  cursor: pointer;
  width: 220px;
  height: 80px;
  & span {
    font-size: 1rem;
  }
  &.selected {
    background-color: #002968;
    border-color: #002968;
    color: #fff;
    cursor: default;
  }
  white-space: nowrap;
  & > div {
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;
const ImgContainer = styled.div`
  & img {
    border: 1px solid #ccc;
    padding: 10px;
  }
`;
