import { useState, useRef } from "react";
import styled from "styled-components";
import { AESEncrypt } from "@nait-aits/helper/cryptography";
import {
  ButtonPriCont,
  ButtonPriText,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "common/components";
import { ShareIcon } from "common/icons";
import CopyToClipboard from "react-copy-to-clipboard";
import { useCtx } from "../Context";

const ShareControl = () => {
  const { lsKey, lsKeyStartTerm, lsKeyMax, lsKeyWithGPA } = useCtx();
  const [showShare, setShowShare] = useState(false);
  const textarea = useRef(null as HTMLTextAreaElement | null);
  const copy = showShare
    ? `${window.location}/${AESEncrypt(
        JSON.stringify({
          [lsKey]: localStorage[lsKey],
          [lsKeyStartTerm]: localStorage[lsKeyStartTerm],
          [lsKeyMax]: localStorage[lsKeyMax],
          [lsKeyWithGPA]: localStorage[lsKeyWithGPA],
        }),
        "schedulePlanner"
      )}`
    : "";

  return (
    <>
      <ButtonPriCont
        onClick={() => {
          setShowShare(true);
          setTimeout(() => textarea.current?.select());
        }}
        startIcon={<ShareIcon />}
      >
        Share
      </ButtonPriCont>
      {showShare && (
        <Dialog fullWidth open>
          <DialogTitle>Share Your Schedule</DialogTitle>
          <DialogContent>
            <ShareArea ref={textarea} value={copy} readOnly />
          </DialogContent>
          <DialogActions>
            <CopyToClipboard text={copy} onCopy={() => setShowShare(false)}>
              <ButtonPriCont>Copy URL to Clipboard</ButtonPriCont>
            </CopyToClipboard>
            <ButtonPriText onClick={() => setShowShare(false)}>
              Close
            </ButtonPriText>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default ShareControl;

const ShareArea = styled.textarea`
  width: 100%;
  height: 200px;
  resize: none;
`;
