import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { MuiThemeProvider } from "@material-ui/core/styles";
import muiThere from "muiTheme";
import appStore from "store";
import { ConfirmBox } from "common/confirmBox";
import Home from "pages/home";
import NotFound from "pages/notFound";
import SchedulePlanner from "pages/schedulePlanner";

const App = () => (
  <MuiThemeProvider theme={muiThere}>
    <Provider store={appStore.store}>
      <ConfirmBox />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/SchedulePlanner/:programId/:year"
            element={<SchedulePlanner />}
          />
          <Route
            path="/SchedulePlanner/:programId/:year/:share"
            element={<SchedulePlanner />}
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  </MuiThemeProvider>
);

export default App;
